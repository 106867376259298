let header = document.querySelector('#main-header')

window.addEventListener('scroll', () => {
    if (window.scrollY > 0) {
        header.classList.remove('container');
        header.classList.remove('is-fixed');
      
    } 
    if (window.scrollY < 250) {
        header.classList.add('container');
        header.classList.add('is-fixed');
        
    }
    
});

function addPulseAnimation(element) {
    element.style.border = '2px solid red';
    element.classList.add('pulse-animation');
    setTimeout(() => {
        element.style.border = 'none';
        element.classList.remove('pulse-animation');
    }, 5000);
}

// Função principal para scroll suave
function scrollToElement(element) {
    window.scroll({
        behavior: 'smooth',
        left: 0,
        bottom: element.offsetBottom
    });
}

// Anexa eventos de clique aos links de contato
document.addEventListener('DOMContentLoaded', function() {
    const contactLink = document.querySelector('#test');
    const contactSection = document.getElementById('contato');

    if (contactLink) {
        contactLink.addEventListener('click', function(event) {
            event.preventDefault();
            scrollToElement(contactSection);
            addPulseAnimation(contactSection);
        });
    }
});